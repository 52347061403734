import { Store, connectTo } from "aurelia-store";
import { SingletonService } from "./singleton";
import { PLATFORM } from "aurelia-pal";
import {
  Router,
  RouteConfig,
  RouterConfiguration,
  NavigationInstruction,
  Next,
  PipelineStep,
  Redirect,
} from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { Routes } from "routes";
import { inject, NewInstance, View } from 'aurelia-framework';
import { AuthService, FetchConfig } from "aurelia-auth";
import { AuthorizeStep } from "aurelia-auth";
import { DialogService } from "aurelia-dialog";
import { NotificationHttpClient } from "http_clients/NotificationHttpClient";
import * as Zenbruh  from "@sentry/browser"
import { default as env } from '../config/environment.json'


// ?
import "bootstrap"; // load bootstrap JavaScript
import { I18N } from "aurelia-i18n";
import { EventAggregator } from "aurelia-event-aggregator";
import {
  addWallets,
  addWallet,
  addTransactions,
  updateWallet,
  deleteWallet,
} from "services/actions/walletActions";
import { State } from "state";
import { WalletService } from "services/wallets.service";
import { WebSocketService } from "services/websocket.service";
import {
  addBank,
  addBanks,
  addCountry,
  addInstitution,
  addTransaction,
  deleteBanks,
} from "services/actions/bankActions";
import { YapilyService } from "services/yapily.service";
import {
  setMakePaymentAmountAndCurrency,
  setMakePaymentNote,
  setMakePaymentReceiver,
  setMakePaymentSenderWallet,
  setMakePaymentIsMK,
} from "services/actions/makePaymentsActions";
import { setMeAsUser } from "services/actions/meActions";
import { UserService } from "services/user.service";
import { selectAccountIndexAction } from "services/actions/selectAccountIndexAction";
import { setNotificationsInState } from "services/actions/notificationsActions";
import {
  setContactInfoInState,
  setContactToAddSelectedInState,
  setContactsInState,
  setContactToAcceptInState,
  setContactToGetDetails,
  updateContactInState,
  deleteContact,
  setRecipientsInState,
  deleteRecipient,
  addOneRecipientInState,
  setSearchInState
} from "services/actions/contactsActions";
import { updateNotification } from "services/actions/notificationsActions";
import { NotificationsService } from "services/notifications.service";
import { ContactsService } from "services/contacts.service";
import { InAppNotification } from "services/models/InAppNotification";
import localforage from "localforage";
@connectTo()
@autoinject()

export class App {
  public router: Router;
  public minDesktopWidth: 960;
  private notification: InAppNotification = undefined;
  private isShowingNotification: boolean = false;
  private stateCounter: number = 0;

  constructor(
    router,
    private fetchConfig: FetchConfig,
    private dialogService: DialogService,
    appRouterConfig,
    private i18n: I18N,
    private singleton: SingletonService,
    private eventAggregator: EventAggregator,
    private authService: AuthService,
    private notificationHttpClient: NotificationHttpClient,
    private store: Store<State>,
    private webSocketService: WebSocketService,
    private walletsService: WalletService,
    private yapilyService: YapilyService,
    private userService: UserService,
    private notificationService: NotificationsService,
    private contactsService: ContactsService,

    private account: {identification: string } // Your account object

  ) {
    //Some comment for a new realease
    let sampleRate = (env.environment === "development" || env.environment === "test") ? 1: 0.1;
    this.account = {
      identification: '', // Initialize with an empty string or the actual value you want to compare
    };
    Zenbruh.init({ 
      dsn: 'https://b04b17f7996a4705b5c9a74d930950e2@sentry.mybanka.eu/6',
      // dsn: 'https://b04b17f7996a4705b5c9a74d930950e2@mybanka.eu/zenbruh/6',
      environment: env.environment, 
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: sampleRate,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          // Keep the Replay integration as before
          Zenbruh.replayIntegration({
            blockAllMedia: false,
          }),
        ]
    }),
    this.router = router;
    this.fetchConfig = fetchConfig;
    this.checkIfLangOrSetDefault();
    this.loadStoreActions();
  }

  
  activate() {
    this.fetchConfig.configure();
    this.startListeningToLoginEvents();
  }
  
  async bind() {
    this.startSW();
    this.store.dispatch("set.me.user", await this.authService.getMe());
    this.eventAggregator.subscribe("app.show.notification", (inAppNotif: InAppNotification) => this.showInAppNotification(inAppNotif));
    try {
      if (this.authService.isAuthenticated())
      this.eventAggregator.publish("app.started");
    } catch (e) { /* empty */ }
  }

  async deactivate() {
    this.authService.logout("login");
  }

  /**
   * Get favourite language on app start from browser
   * or choose a default
   */
  checkIfLangOrSetDefault() {
    if (
      this.singleton.getMyFavoriteLanguage() === null ||
      this.singleton.getMyFavoriteLanguage() === ""
    ) {
      let lang = navigator.language.substring(0, 2);
      this.singleton.setMyFavoriteLanguage(lang);
    }

    this.i18n.setLocale(this.singleton.getMyFavoriteLanguage());
  }

  /**
   * Configuring main app wide router
   * @param config
   * @param router
   */
  public configureRouter(
    config: RouterConfiguration,
    router: Router
  ): Promise<void> | PromiseLike<void> | void {
    config.title = "MyBanka";
    config.addPipelineStep("authorize", AuthorizeStep);
    config.addPipelineStep("authorize", ExpiredTokenStep);
    config.addPipelineStep("authorize", CheckRolesStep);
    config.map(Routes);
    
    // Detect if the environment has home as as default page (only APK env for now)
    const isHomeDefaultPage = env.landingpage === "home";

    // Set the initial route based on the device type
    if (isHomeDefaultPage) {
      config.map([
        { route: [''], redirect: 'home' },
        { moduleId: PLATFORM.moduleName("./pages/home/welcome/welcome"),                route: ["welcome"],          title: 'home.welcome.tabtitle' , name: "welcome",  auth: false, nav: true, settings: { roles: [] },},
      ]);
    } else {
      config.map([
        // other routes specific to desktop
        { moduleId: PLATFORM.moduleName("./pages/home/welcome/welcome"),                route: ["", "welcome"],      title: 'home.welcome.tabtitle' , name: "welcome",  auth: false, nav: true, settings: { roles: [] },},
      ]);
    }
            
    config.options.pushState = true;
    config.options.root = "/";

    /* 404 page */
    let forNotfound = {
      route: ["not-found"],
      name: "not-found",
      moduleId: PLATFORM.moduleName("./pages/home/not-found/not-found"),
      auth: false,
      nav: false,
      settings: { roles: [] },
      title: "404 - Not found",
    };

    config.mapUnknownRoutes(forNotfound);

    this.router = router;
  }

  async saveState(newState){
    let state = "State"
    let currentState = newState
    await localforage.setItem(state, newState)
  }

  /**
   * This will load all action to mutate our State
   */
  loadStoreActions() {
    //Select account
    this.store.registerAction("account.selected.update", selectAccountIndexAction);
    //Wallet actionsInAppNotification
    this.store.registerAction("wallets.add.one", updateWallet);
    this.store.registerAction("wallets.add.one.state", updateWallet);
    this.store.registerAction("wallets.add.many", addWallets);
    this.store.registerAction("wallets.delete.one.state", deleteWallet);
    this.store.registerAction("wallet.transactions.add.many", addTransactions);
    this.store.registerAction("wallet.transactions.fake.one", addTransaction);
    /* Banks actions */
    this.store.registerAction("banks.accounts.add.one", addBank);
    this.store.registerAction("banks.accounts.add.many", addBanks);
    this.store.registerAction("bank.transaction.add.one.state", addTransaction)
    this.store.registerAction("bank.add.one.country", addCountry);
    this.store.registerAction("bank.add.one.institution", addInstitution);
    this.store.registerAction("banks.delete.one.state", deleteBanks);
    /* Payments actions */
    this.store.registerAction(
      "set.make_payment.sender_wallet",
      setMakePaymentSenderWallet
    );
    this.store.registerAction(
      "set.make_payment.recipient",
      setMakePaymentReceiver
    );
    this.store.registerAction(
      "set.make_payment.amount.and.currency",
      setMakePaymentAmountAndCurrency
    );
    this.store.registerAction("set.make_payment.note", setMakePaymentNote);
    this.store.registerAction("set.make_payment.mk", setMakePaymentIsMK);
    /* userMicroservice action */
    this.store.registerAction("set.me.user", setMeAsUser);
    this.store.registerAction(
      "set.contact.profile.info",
      setContactInfoInState
    );
    /* notification actions */
    this.store.registerAction(
      "notifications.add.many",
      setNotificationsInState
    );
    this.store.registerAction("notification.update.one", updateNotification);
    /* Friends&Recipients */
    this.store.registerAction("contacts.add.many.state", setContactsInState);
    this.store.registerAction("contacts.add.one.state", updateContactInState);
    this.store.registerAction("contacts.add.search.state", setSearchInState);
    this.store.registerAction("contacts.adding.search.selected", setContactToAddSelectedInState);
    this.store.registerAction("contacts.adding.accept.selected", setContactToAcceptInState);
    this.store.registerAction("contacts.adding.details", setContactToGetDetails);
    this.store.registerAction("contacts.remove.one.state", deleteContact);
    this.store.registerAction("recipients.add.many.state", setRecipientsInState);
    this.store.registerAction("recipient.remove.one.state", deleteRecipient);
    this.store.registerAction("recipient.add.one.state", addOneRecipientInState)
  }

  /**
   * 1. actions/walletActions.ts: create action that mutates the store in the way we want
   * 2. app.ts: Register action``` this.store.registerAction('wallets.add.one.state', addWallet)```
   * 3. page => create_wallet.ts => newWallet = new Wallet() => wants to publish to backend and update the store
   * 4. page => create_wallet.ts: call an event: this.eventAggregator.publish('wallets.add.one.backend', newWallet)
   * 5. wallets.service.ts => subscribe and handle event
   *      this.eventAggregator.subscribe('wallets.add.one.backend', (wallet: WalletModel) => this.updateWallet(wallet))
   * 6. wallets.service.ts => handle creation of wallet by sending request to websocket
   *      const verb = "POST";
   *      const url = `${env.paymail_wallet_api}/wallet/`;
   *      const body = newWallet;
   *      const token = localStorage.getItem('aurelia_token');
   *      const direction = "outgoing";
   *      const createWalletRequest = new WebSocketRequestModel(verb, url, body, token, direction);
   *      this.webSocketService.sendMessage(createWalletRequest)
   * 7. WebSocket Backend will receive the request and send it to the backend
   * 8. WebSocket Backend will receive the response and send it to all clients (phone, laptop, desktop...)
   * 9. websocket.service.ts => should listen to { wallet: {} } => the format needs to be clear
   *          else if('wallet' in responseFromBackend) {
   *            this.handleWallet(responseFromBackend.wallet as WalletModel)
   *          }
   * 10. websocket.service.ts => will call the appropriate service to handle the received message
   *          this.eventAggregator.publish('wallets.add.one.state', wallet);
   * 11. wallets.service.ts => listens to these event to mutate the store based on the receive message
   * 12. dispatches a mutation action on the store based on the received newWallet
   *          await this.store.dispatch("wallets.add.one.state", wallet)
   *
   */

  async startSW() {
    if ("serviceWorker" in navigator) {
      try {
        let registration = await navigator.serviceWorker.register("sw.js");
        this.singleton.setRegistration(registration);
        await this.registerPushNotification(registration);
        this.startListeningToServiceWorkerMessages();
        this.initialiseSwCommunication();
      } catch (e) {
        this.startListeningToServiceWorkerMessages();
      }
    }
  }
  /* this function will tell the service worker to fetch all require reactive data */
  initialiseSwCommunication() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.controller.postMessage({
        action: "fetchWalletsData",
        token: localStorage.getItem("aurelia_token"),
      });
    }
  }

  async registerPushNotification(registration) {
    try {
      let subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BOFwv49nKey3_M5l_A-734cY7ehdkfxCn84UdQHN-2tgYHzodWM4sQ6IahO1bgR-cXB7IaCpvBXHoFibfWW2Hww",
      });
      await this.sendRegistrationToBackEnd(subscription);
    } catch (e) {
      console.log(e);
    }
  }

  async sendRegistrationToBackEnd(subscription) {
    let userAgent = navigator.userAgent;

    return await this.notificationHttpClient.fetch("/notification/register", {
      method: "POST",
      body: JSON.stringify({
        registration: subscription,
        deviceAgent: userAgent,
      }),
    });
  }

  goBack() {
    this.router.navigateBack();
  }

  /**
   * This function listens to aurelia-auth even when user logs in/off
   */
  startListeningToLoginEvents() {
    this.eventAggregator.subscribe("auth:login", (data) => {
      this.handleIsFirstTime(data);
    });
  }

  async handleIsFirstTime(data) {
    if (!data.user.isFirstTime) return;
  }

  loadingNavigation(state: string) {
  }
  /**
   * This function listens to Service Worker message
   * and take appropriate action
   */
  startListeningToServiceWorkerMessages() {
    // Set up a listener for messages from the service worker
    try {
      navigator.serviceWorker.addEventListener("message", (event) => {
        const payload = event.data.payload;
        console.log("calling ShowInAppNotification");
        this.showInAppNotification(payload);
      });
    } catch (e) {
      console.log(e);
    }
  }
  /**
   * This function shows a payload with
   * title, body and icon
   * as an In-App Notification
   * for magical feelings
   */
  showInAppNotification(payload: InAppNotification) {
    if(this.isShowingNotification)
      return
    this.notification = payload;
    this.isShowingNotification = true;
  }


  async  stateChanged(newState, oldState){
    try{
      console.log("State " + this.stateCounter + ": ");
      console.log(newState);
      if (oldState) {
       for (let i = 0; i < oldState.wallets.length; i++) {
         if (!newState.wallets[i].transactions) {
           newState.wallets[i].transactions = oldState.wallets[i].transactions;
         }
       }
     }
    }catch(e){
      throw new Error("State Error")
    }
  
   this.stateCounter++;
   await this.saveState(newState);
 }
  
}

@autoinject()
class CheckRolesStep implements PipelineStep {
  constructor(
    private authService: AuthService,
    private singleton: SingletonService
  ) {}

  /**
   * Check if the user has the necessary roles to access the route
   */
  public async run(
    navigationInstruction: NavigationInstruction,
    next: Next
  ): Promise<any> {
    const instructions = navigationInstruction.getAllInstructions();

    // If roles are empty, allow access
    if (instructions.some((i) => i.config.settings.roles.length === 0)) {
      return next();
    }

    const role = await this.checkRoles();

    const hasRole = instructions.some((instr) => {
      const roles: Array<string> = instr.config.settings.roles;
      return roles && roles.includes(role);
    });

    return hasRole ? next() : next.cancel(new Redirect("welcome"));
  }

  /**
   * Get the user's role from the token
   */
  async checkRoles(): Promise<string> {
    let role = "user";
    let token;
    try {
      token = await this.singleton.getMe();
    } catch (e) {
      token.role = role;
    }

    return token.role;
  }
}

/** Verify presence of token on routes with auth: true
 * otherwise redirects to login.
 */
@autoinject()
class ExpiredTokenStep implements PipelineStep {
  constructor(
    private authService: AuthService,
    private singleton: SingletonService
  ) {}

  /**
   * Redirect to login page if token has expired
   */
  public async run(
    navigationInstruction: NavigationInstruction,
    next: Next
  ): Promise<any> {
    const instructions: any = navigationInstruction.getAllInstructions();

    // These pages do not require a token
    if (instructions[0].config.auth === false) return next();

    try {
      let profile = await this.authService.getMe();
      this.singleton.setMe(profile);
      return next();
    } catch (error) {
      return next.cancel(new Redirect("login"));
    }
  }
}
@autoinject()
export class dialogsWidthChecker {
  constructor(private dialogService: DialogService, private router: Router) {}

  checkWidth(routerViewDestination, route) {
    const dialogsWidth = 960;
    if (window.innerWidth > dialogsWidth) {
      this.dialogService
        .open({ viewModel: routerViewDestination })
        .whenClosed((response) => {});
    } else {
      this.router.navigateToRoute(route);
    }
  }
}
